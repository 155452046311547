
import Vue from "vue";
import { Location } from "vue-router";
import { mapGetters } from "vuex";

type ItemMenu = {
  title: string;
  classes: string;
  icon: string;
  to: string | Location;
  roles: Array<string>;
  group?: string;
  children?: Array<ItemMenu>;
};

export default Vue.extend({
  name: "CoreDrawer",
  data: () => ({
    drawer: true as boolean,
    mini: true as boolean,
    items: [
      {
        icon: "mdi-home",
        title: "home",
        classes: "",
        to: "/",
        roles: [""],
      },
      {
        icon: "mdi-account",
        title: "user",
        classes: "",
        to: "/admin/users",
        roles: ["admin"],
      },
      {
        icon: "mdi-linux",
        title: "basis-cities",
        classes: "",
        to: "/admin/basis-cities",
        roles: ["admin"],
      },
      {
        icon: "settings",
        title: "sysmanager",
        classes: "",
        to: "/admin/sysmanager",
        roles: ["admin"],
      },
      {
        icon: "monetization_on",
        title: "profit-simple",
        classes: "",
        to: { name: "Profit", params: { adminAsN1: true } },
        roles: ["admin"],
      },
      {
        icon: "post_add",
        title: "registrations",
        classes: "hidden-md-and-up",
        roles: ["admin", "N2", "N3"],
        group: "/crud",
        children: [
          {
            icon: "mdi-barn",
            title: "ownership",
            to: "Ownership",
            roles: [""],
          },
          {
            icon: "border_all",
            title: "field",
            to: "Field",
            roles: [""],
          },
          {
            icon: "mdi-cow",
            title: "cattle",
            to: "Cattle",
            roles: [""],
          },
          // {
          //   icon: "money_off",
          //   title: "outgoing",
          //   to: "Outgoing",
          //   roles: [""],
          // },
          {
            icon: "attach_money",
            title: "income",
            to: "Income",
            roles: [""],
          },
          {
            icon: "mdi-truck",
            title: "shipping",
            to: "Shipping",
            roles: [""],
          },
          {
            icon: "mdi-calculator",
            title: "budget",
            to: "Budget",
            roles: [""],
          },
        ],
      },
      {
        icon: "attach_money",
        title: "profit",
        classes: "hidden-md-and-up",
        to: "/profit",
        roles: [""],
      },
      {
        icon: "mdi-chart-bar-stacked",
        title: "analytics",
        classes: "hidden-md-and-up",
        roles: ["admin", "N2", "N3"],
        group: "/analytics",
        children: [
          {
            icon: "mdi-chart-bell-curve",
            title: "basis-history",
            to: "basis_history",
            roles: [""],
          },
          {
            icon: "mdi-chart-areaspline",
            title: "Projeção Basis",
            to: "basis_projection",
            roles: [""],
          },
        ],
      },
      {
        icon: "mdi-apple-safari",
        title: "AgrinvestGo",
        classes: "hidden-md-and-up",
        href: "https://inteligencia.agrinvest.agr.br/",
        roles: [""],
      },
      {
        icon: "school",
        title: "utr",
        classes: "hidden-md-and-up",
        href: "https://inteligencia.agrinvest.agr.br/utr",
        roles: [""],
      },
    ] as Array<ItemMenu>,
  }),
  created() {
    this.items.forEach((i) => {
      if (i.title === "Rentabilidade") {
        if (this.user.userRole === "N1") {
          i.to = { name: "Profit", params: { adminAsN1: "true" } };
        }
        i.to = { name: "Profit" };
      }
    });
  },
  computed: {
    ...mapGetters({
      user: "getLoggedUser",
    }),
    computedItems(): ItemMenu[] {
      return this.items.map(this.mapItem);
    },
  },
  methods: {
    mapItem(item: ItemMenu): ItemMenu {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },
    hasRoles(roles: Array<string>): boolean {
      let r = roles.find(
        (role) => !role || (role && role === this.user.userRole)
      );
      return r || r == "" ? true : false;
    },
  },
});
